<template>
  <div></div>
</template>
<script>
import Service from "@/services/base.service";

export default {
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { id } = this.$route.params;
      if (!id) {
        alert("invalid url");
      }
      const BaseService = new Service("link");
      const { data } = await BaseService.getListData({ key: id });
      if (data) {
        window.location.replace(data);
      } else {
        alert("url not found");
      }
    },
  },
};
</script>
